import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Header from "../components/header"
import Img from "gatsby-image"
import Hidden from "@material-ui/core/Hidden"

const useStyles = makeStyles((theme) => ({
  backgroundRoot: {
    position:'fixed',
    width:'100%',
    height:'100%',
    top:'0px',
    left:'0px',
    zIndex:-1,
    userSelect: 'none',
    display: 'flex',
    flexDirection:'column',
  },
  backgroundBelowHeader:{
    flexGrow:1,
    display: 'flex',
    flexDirection:'row',
  },
  backgroundLeftSide:{
    width:'40%',
    backgroundColor:'green'
  },
  image:{
    flexGrow:1,
    height:'100%',
    width:'100%'
  },

  root:{
    display: 'flex',
    flexDirection:'column',
    width:'100%',
    minHeight:'100vh',
  },
  belowHeaderHorizontal:{
    flexGrow:1,
    display: 'flex',
    flexDirection:'row',
  },
  belowHeaderVertical:{
    flexGrow:1,
    display: 'flex',
    flexDirection:'column',
  },
  leftSide:{
    width:'40%',
    display: 'flex',
  },
  rightSide:{
    flexGrow:1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topSide:{
    flexGrow:1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomSide:{
    display: 'flex',
    height:'20vh',
  },

}));

export default function SplitLayout(props) {
  const classes = useStyles();


  return(
    <React.Fragment>
      <Hidden smDown>
        <div className={classes.backgroundRoot}>
          <Header spacerOnly={true}/>
          <div className={classes.backgroundBelowHeader}>
            <div className={classes.backgroundLeftSide}>
              <Img className={classes.image} draggable={false} fluid={props.image} />
            </div>
          </div>
        </div>
      </Hidden>
      <div className={classes.root}>
        <Header />
        <Hidden smDown>
          <div className={classes.belowHeaderHorizontal}>
            <div className={classes.leftSide}>
              {/*<Img className={classes.image} draggable={false} fluid={props.image} />*/}
            </div>
            <div className={classes.rightSide}>
              {props.children}
            </div>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.belowHeaderVertical}>
            <div className={classes.topSide}>
              {props.children}
            </div>
            <div className={classes.bottomSide}>
              <Img className={classes.image} draggable={false} fluid={props.image} />
            </div>
          </div>
        </Hidden>
      </div>
    </React.Fragment>
  );
}
