import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import SplitLayout from "../components/splitlayout"
import { graphql, useStaticQuery } from "gatsby"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  textArea:{
    display: 'flex',
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textAreaInner:{
    flexGrow:1,
    maxWidth: '5in',

  },
}));

export default function About() {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "riverstones.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const Para = function Para(props) {
    return (
      <Typography
        variant="body1"
        color="inherit"
        align="justify"
        gutterBottom="true"
      >
        {props.children}
      </Typography>
    );
  }

  return(
    <SplitLayout image={data.image.childImageSharp.fluid}>
      <div className={classes.textArea}>
        <div className={classes.textAreaInner}>
          <Typography variant="h4" align="center" gutterBottom="true">
            About
          </Typography>
          <Para>
            The prospect of capturing the beauty of British Columbia and its people
            motivates me to get out there over and over again.
          </Para>
          <Para>
            I look closer when I have the camera with me. I discover
            what I might miss otherwise. The world looks different through the lens.
            It's almost as if I can discover the same place twice.
          </Para>
          <Para>
            I still learning something new about photography every day.
            Maybe our paths cross one day, and we can learn something together.
          </Para>
        </div>
      </div>
    </SplitLayout>
  );
}
